<template>
    <div class="mt-n10 mb-10 mt-lg-n50 mb-md-16">
        <v-row no-gutters align="center" justify="end">
            <v-col cols="12" md="auto">
                <div class="w-100 w-md-300px">
                    <v-select v-model="value" v-bind="{ items }" outlined hide-details />
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { BOARD_FORMS_CATEGORIES } from "@/assets/variables";
const items = [{ text: "전체", value: null }, ...Object.values(BOARD_FORMS_CATEGORIES)];

export default {
    data: () => ({
        value: null,
        items,
    }),
    computed: {
        category() {
            return this.$route.query.category || null;
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        category() {
            if (this.value !== this.category) this.init();
        },
        value() {
            if (this.value !== this.category) {
                console.log(this.$route);
                this.$router.push({ query: { ...this.$route.query, category: this.value || undefined, page: 1 } });
            }
        },
    },
    methods: {
        init() {
            this.value = this.category;
        },
    },
};
</script>

<style></style>
