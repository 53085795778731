var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "board-container"
  }, [_vm.code == 'forms' ? _c('forms-list-categories') : _vm._e(), _c('div', {
    staticClass: "board-list-attachment"
  }, [_c('div', {
    staticClass: "row"
  }, _vm._l(_vm.boards, function (board) {
    var _vm$categories$board$, _board$files, _board$files$;
    return _c('div', {
      key: board._id,
      staticClass: "col-6 col-sm-4 col-md-3"
    }, [_c('div', {
      staticClass: "board-list-attachment__inner"
    }, [_c('div', [_c('div', {
      staticClass: "board-list-attachment__img-wrap"
    }, [_c('div', {
      staticClass: "board-list-attachment__img",
      style: {
        backgroundImage: `url('${board.thumb}')`
      }
    })]), _c('div', {
      staticClass: "board-list-attachment__con"
    }, [board !== null && board !== void 0 && board.category ? _c('div', {
      staticClass: "mb-4"
    }, [_c('span', {
      staticClass: "font-size-14 grey-68--text"
    }, [_vm._v(_vm._s((_vm$categories$board$ = _vm.categories[board === null || board === void 0 ? void 0 : board.category]) === null || _vm$categories$board$ === void 0 ? void 0 : _vm$categories$board$.text))])]) : _vm._e(), _c('div', {
      staticClass: "board-list-attachment__tit-wrap mb-10 mb-md-16"
    }, [_c('div', {
      staticClass: "board-list-attachment__tit ellip"
    }, [_c('span', [_vm._v(_vm._s(board.subject))])])]), _c('v-divider', {
      staticClass: "grey-da"
    }), _c('div', {
      staticClass: "py-6"
    }, [_c('v-row', {
      attrs: {
        "no-gutters": ""
      }
    }, [_c('v-col', [_c('v-btn', {
      attrs: {
        "small": "",
        "block": "",
        "outlined": "",
        "color": "grey-da"
      },
      on: {
        "click": function ($event) {
          return _vm.$emit('click', board);
        }
      }
    }, [_c('span', {
      staticClass: "primary--text"
    }, [_vm._v("보기")])])], 1), _c('v-col', {
      staticClass: "ml-sm-n1"
    }, [_c('v-btn', {
      attrs: {
        "href": board === null || board === void 0 ? void 0 : (_board$files = board.files) === null || _board$files === void 0 ? void 0 : (_board$files$ = _board$files[0]) === null || _board$files$ === void 0 ? void 0 : _board$files$.url,
        "download": "",
        "small": "",
        "block": "",
        "outlined": "",
        "color": "grey-da"
      },
      on: {
        "click": function ($event) {
          $event.stopPropagation();
        }
      }
    }, [_c('span', {
      staticClass: "primary--text"
    }, [_vm._v("다운로드")])])], 1)], 1)], 1), _c('v-divider', {
      staticClass: "primary"
    })], 1)])])]);
  }), 0)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }