var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mt-n10 mb-10 mt-lg-n50 mb-md-16"
  }, [_c('v-row', {
    attrs: {
      "no-gutters": "",
      "align": "center",
      "justify": "end"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('div', {
    staticClass: "w-100 w-md-300px"
  }, [_c('v-select', _vm._b({
    attrs: {
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.value,
      callback: function ($$v) {
        _vm.value = $$v;
      },
      expression: "value"
    }
  }, 'v-select', {
    items: _vm.items
  }, false))], 1)])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }